.m-checkboxGroup {
    &.-frame {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border: 1px solid rgba($primary-color, 0.1);
        border-radius: 6px;
        padding: 10px 0;
        margin: 20px 0;
        box-shadow: 0 0px 0px 0px rgba($black, 0.2),
            0 0px 2px 0 rgba($black, 0.2);

        .a-checkbox {
            width: 40%;
            margin-right: 20px;
        }

        .m-checkboxGroup__label {
            position: absolute;
            top: -14px;
            left: 14px;
            background-color: white;
            font-size: 14px;
            padding: 0 10px;
            color: $primary-color;
            font-weight: 600;

            &.-required {
                &::after {
                    position: relative;
                    content: '*';
                    color: $red;
                    right: -5px;
                }
            }
        }

        .m-checkboxGroup__options {
            padding: 0 20px;
        }

        &.-error {
            border-color: $red;

            .m-checkboxGroup__error {
                position: absolute;
                bottom: -20px;
            }
        }
    }

    &__label {
        font-weight: 600;

        &.-required {
            &::after {
                position: relative;
                content: '*';
                color: $red;
                right: -5px;
            }
        }
    }

    &__options {
        width: 100%;
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;

        @include media-down(xs) {
            flex-direction: column;
        }

        .a-checkbox {
            width: 250px;
            margin-bottom: 20px;
            margin-right: 50px;

            @include media-down(xs) {
                width: auto;
            }
        }

        .a-btn {
            width: 100%;
        }
    }

    &__error {
        display: block;
        color: $red;
        opacity: 0;
        font-size: 13px;
        transition: opacity 0.3s;
        letter-spacing: -0.2px;
        line-height: 16px;
        margin-top: 3px;

        &.-active {
            opacity: 1;
        }
    }
}
