.a-toggleSwitch {
    position: relative;
    display: inline-block;
    margin-bottom: 24px;

    &.-disabled {
        opacity: 0.5;
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    label {
        display: flex;
        align-items: center;
        width: fit-content;
    }

    &__label {
        margin-left: 15px;
        font-size: 14px;
    }

    &__slider {
        position: relative;
        cursor: pointer;
        width: 40px;
        height: 14px;
        background-color: $gray-4;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;

        &:before {
            position: absolute;
            content: '';
            height: 22px;
            width: 22px;
            left: 0px;
            bottom: -4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
        }
    }

    input:checked + .a-toggleSwitch__slider {
        background-color: $primary-lighter-color;
    }

    input:focus + .a-toggleSwitch__slider {
        box-shadow: 0 0 1px $primary-light-color;
    }

    input:checked + .a-toggleSwitch__slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(18px);
        background-color: $primary-color;
    }
}
