.m-termsAndConditions {
    width: 80%;
    text-align: center;

    &__list {
        font-weight: 600;
        margin-bottom: 16px;

        ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 16px 0;
            padding-left: 20px;

            li {
                list-style: disc;
                text-align: start;
            }
        }
    }

    &__bold {
        font-weight: 600;
    }

    a {
        text-decoration: underline;
    }
}
