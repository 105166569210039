.m-textEditor {
    margin-bottom: 15px;

    label {
        font-size: 13px;
        line-height: 16px;
        .a-require-star {
            color: $red;
        }
    }

    &.-required {
        label {
            &::after {
                position: relative;
                content: '*';
                color: $red;
                right: -5px;
            }
        }
    }

    &.-error {
        .m-textEditor__editor {
            border-color: $red;
        }
    }

    &.-disabled {
        label {
            color: rgba($black, 0.7);
        }

        input {
            color: $black;
            opacity: 0.7;
        }

        .m-textEditor__editor {
            color: rgba($black, 0.7);
        }
    }

    &__editor {
        border-radius: 6px;
        border: 1px solid $gray-5;
        background-color: $white;
        padding: 0 10px;
    }

    &__toolbar {
        border-radius: 6px !important;
        border: 1px solid $gray-5 !important;
        margin-bottom: 3px !important;
    }
}
