.m-optionsGroup {
    &__button {
        background-color: #172b4d;
        color: white;
        padding: 8px 10px;
        border-radius: 6px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 14px;
        cursor: pointer;
    }

    &__label {
        font-weight: 600;
        display: inline-block;
        margin-bottom: 15px;
    }

    &.-disabled {
        .m-optionsGroup__button {
            background-color: $gray-5;
            cursor: default;
        }
    }

    &.-otherInfo {
        .m-modal {
            .m-inputGroup,
            .m-selectGroup,
            .m-multiselectGroup,
            .m-textarea {
                margin-bottom: 10px;
            }

            .m-textarea {
                textarea {
                    height: 128px;
                }
            }
        }
    }

    .m-modal {
        .a-btn {
            width: 100%;
        }

        .m-optionsGroup__fixedButton {
            width: 100%;
            position: sticky;
            bottom: 0;
            background-color: white;
            min-height: 60px;
            display: flex;
            align-items: center;
        }
    }

    .m-modal__content {
        padding-bottom: 0;
    }
}
