.m-noAuth {
    min-height: 100vh;
    padding: 20px;
    background: url(../../../images/default-background.jpg) 0 50% / cover
        no-repeat;

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 90vh;
    }

    &__form {
        .m-inputGroup,
        .m-selectGroup,
        .m-dateTimePicker,
        .m-fileDragAndDrop {
            max-width: 300px;
            width: 100%;
        }

        &.-register {
            width: 100%;
        }
    }

    &__logo {
        background-image: url(../../../images/logo.png);
        height: 80px;
        width: 150px;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__language {
        width: fit-content;

        .m-languagePicker__container {
            background-color: $primary-color;
            color: $white;
            padding: 5px 15px;
            border-radius: 5px;
        }

        .a-chevron {
            border-right-color: $white;
            border-bottom-color: $white;
        }
    }

    &__formAuthMessage {
        font-size: 12px;
        position: absolute;
        text-align: center;
        bottom: -20px;

        @include media-down(xs) {
            font-size: 11px;
        }
    }
}
