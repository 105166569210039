.m-main {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    transition: 0.5s;
    max-width: $main-closed-width;
    margin-left: $sidebar-closed-width;
    padding-top: $header-height;

    @include media-down(s) {
        max-width: $main-closed-mobile-width;
        margin-left: $sidebar-closed-mobile-width;
    }

    &.-sidebarIsOpen {
        transition: 0.5s;

        @include media-up(m) {
            max-width: $main-width;
            margin-left: $sidebar-width;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        height: 100%;
        margin: 20px auto;
        padding: 0 20px;

        ._wr {
            max-width: 100%;
            margin: 0;

            // @include media-up(xxl) {
            //     margin: inherit;
            // }
        }
    }

    &__content {
        width: 100%;
    }

    &__contentContainer {
        position: relative;
        padding: 40px 36px 24px;
        background-color: $white;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 2px 1px -1px rgba(0, 0, 0, 0.12);

        &.-tabs {
            padding: 0px 36px 24px;
        }

        &.-minHeight {
            min-height: 90vh;
        }

        .buttonsContainer {
            display: flex;
            margin-top: 20px;
            flex-wrap: wrap;

            button {
                margin-top: 5px;
                margin-right: 20px;
            }
        }
    }

    canvas {
        margin-top: 20px;
    }

    &__contentPrint {
        position: relative;

        .printBtn {
            position: absolute;
            right: 25px;
            top: 0;
            height: 45px;
            width: 45px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__rightBtn {
        position: absolute;
        right: 35px;
        top: 20px;
    }
}
