.m-multiselectGroup {
    width: 100%;
    margin-bottom: 15px;

    label {
        font-size: 13px;

        .a-require-star {
            color: $color-error;
        }
    }

    &.-disabled {
        opacity: 0.7;

        label {
            color: #828a8d;
        }

        input {
            color: #828a8d;
            background-color: $white;
        }
    }

    &__container {
        position: relative;
    }

    &__multiselect {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid gray;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        border: 1px solid $gray-5;
        background-color: $white;
        outline: 0;
        color: $secondary-color;
        font-size: 13px;
        transition: border-color 0.3s, background-color 0.3s;
        filter: none;

        &:invalid {
            box-shadow: none;
        }

        &:focus {
            background-color: $white;
            border-color: $secondary-color;

            ~ svg {
                stroke: $secondary-color;
            }
        }

        &.-open {
            border-color: $secondary-color;
            outline: 0;

            .m-selectGroup__arrow {
                transform: translateY(-50%) rotate(225deg);
            }
        }

        &.-error {
            border-color: $red;
        }
    }

    &__removeAll {
        height: 15px;
        width: 15px;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        margin: 0 5px;
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            height: 1px;
            width: 100%;
            top: 50%;
            background: $gray-7;
        }

        &::before {
            transform: translateY(-50%) rotate(-45deg);
        }

        &::after {
            transform: translateY(-50%) rotate(45deg);
        }
    }

    &__items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        min-height: 44px;
        padding: 2.5px;
        padding-right: 20px;
    }

    &__placeholder {
        position: absolute;
        color: $gray-6;
        font-size: 13px;
        padding: 0 12.5px;
        line-height: 16px;
    }

    &__item {
        background: $primary-light-color;
        border: 1px solid rgba($primary-color, 0.3);
        border-radius: 5px;
        padding: 5px 10px;
        display: flex;
        height: 34px;
        align-items: center;
        justify-content: space-between;
        margin: 2.5px;
        color: $black;
        animation-name: show;
        animation-duration: 0.3s;

        .label {
            font-size: 11px;
            line-height: 16px;
            letter-spacing: -0.2px;
            margin-right: 15px;
        }

        @keyframes show {
            from {
                transform: scale(0);
            }

            to {
                transform: scale(1);
            }
        }
    }

    &__removeItem {
        height: 10px;
        width: 10px;
        position: relative;
        display: flex;
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            height: 1px;
            width: 100%;
            top: 50%;
            background: $black;
        }

        &::before {
            transform: translateY(-50%) rotate(-45deg);
        }

        &::after {
            transform: translateY(-50%) rotate(45deg);
        }
    }

    &__searchItem {
        background-color: transparent;
        color: $black;
        display: flex;
        padding-left: 10px;
    }

    &__search {
        outline: none;
        align-self: center;
        min-width: 20px;
        max-width: 300px;
        color: $black;
        font-size: 13px;

        overflow-x: hidden;

        input {
            width: 50px;
        }
    }

    &__options {
        position: absolute;
        z-index: 22;
        min-height: fit-content;
        background-color: $white;
        border: 1px solid $secondary-color;
        border-radius: 6px;
        width: 100%;
        opacity: 0;
        bottom: 10px;
        pointer-events: none;
        transform: translateY(100%);
        transition: opacity 0.3s, bottom 0.3s;
        overflow: hidden;
        max-height: 150px;
        overflow-y: auto;
        @include scrollbar;

        &.-open {
            opacity: 1;
            pointer-events: initial;
            bottom: -1px;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                position: relative;
                padding: 5px 25px 5px 10px;
                font-size: 13px;
                cursor: pointer;
                word-break: break-all;
                transition: background-color 0.3s;

                &:hover {
                    background-color: rgba($gray-10, 0.5);
                }

                &.-empty {
                    background-color: transparent;
                    cursor: default;
                }

                &.-selected {
                    color: $primary-color;

                    &::after {
                        position: absolute;
                        top: 7px;
                        right: 10px;
                        content: '';
                        transform: rotate(45deg);
                        height: 10px;
                        width: 5px;
                        border-bottom: 1.5px solid currentColor;
                        border-right: 1.5px solid currentColor;
                    }
                }
            }
        }
    }
}
