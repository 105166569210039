.loader-container {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 21;

    .svg-calLoader {
        width: 230px;
        height: 230px;
        transform-origin: 115px 115px;
        animation: 1.4s linear infinite loader-spin;
    }

    .cal-loader__plane {
        fill: $primary-color;
    }
    .cal-loader__path {
        stroke: $primary-color;
        animation: 1.4s ease-in-out infinite loader-path;
    }

    @keyframes loader-spin {
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes loader-path {
        0% {
            stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
        }
        50% {
            stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
        }
        100% {
            stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
        }
    }
}
