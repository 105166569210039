.m-notices {
    &__card {
        // border: 1px solid $gray-5;
        border-radius: 6px;
        margin-bottom: 25px;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
            0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    &__intro {
        background-color: $primary-color;
        color: $white;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        padding: 10px;
    }

    &__content {
        padding: 0px 15px;
        height: 300px;
        overflow-y: auto;

        @include scrollbar;
    }
}
