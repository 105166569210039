@import './assets/scss/style.scss';

body {
    background-color: $main-color;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
