.p-fplOverview {
    position: relative;

    .m-modal {
        h5 {
            margin-bottom: 25px;
        }

        .details {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            color: $gray-19;

            b {
                margin-right: 10px;
            }

            span {
                margin-top: 10px;
            }
        }

        .pibSelection {
            display: flex;
            flex-direction: column;

            .a-btn {
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
        }
    }

    &__refresh {
        display: flex;
        align-items: center;
        height: 30px;
        width: 30px;
        position: absolute;
        right: 10px;
        top: 10px;

        &:hover {
            cursor: pointer;
            svg {
                stroke: $secondary-color;
            }
        }
    }
}
