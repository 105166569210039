.a-btn {
    transition: color 0.3s, background-color 0.3s;
    text-transform: uppercase;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 14px;
    border-radius: 6px;
    cursor: pointer;
    outline: none;
    border: 0;

    &:active,
    &:focus {
        border-style: outset;
        outline: none;
    }

    &.-secondary,
    &.-secondaryDisabled {
        background-color: transparent;
        color: $black;
        padding: 14px 16px;

        svg {
            stroke: $primary-color;
        }

        &:not(.-secondaryDisabled) {
            &:hover {
                background-color: $gray-2;
            }
        }
    }

    &.-primary,
    &.-primaryDisabled {
        background-color: $primary-color;
        color: $white;
        padding: 12px 20px;

        &:not(.-primaryDisabled) {
            &:hover {
                background-color: $gray-14;
            }
        }
    }

    &.-primaryInverse,
    &.-primaryInverseDisabled {
        background-color: $white;
        color: $primary-color;
        padding: 12px 20px;
        border: 1px solid $primary-color;

        svg {
            stroke: $primary-color;
        }

        &:not(.-primaryInverseDisabled) {
            &:hover {
                background-color: $gray-2;
            }
        }
    }

    &.-tertiary,
    &.-tertiaryDisabled {
        background-color: $gray-2;
        color: $black;
        padding: 14px 16px;

        &:not(.-tertiaryDisabled) {
            &:hover {
                background-color: $gray-3;
            }
        }
    }

    &.-basic,
    &.-basicDisabled {
        background-color: transparent;
        color: $primary-color;
    }

    &.-blue,
    &.-blueDisabled {
        background-color: $secondary-color;
        color: $white;
        padding: 12px 20px;

        &:not(.-blueDisabled) {
            &:hover {
                background-color: $gray-14;
            }
        }
    }

    &.-primaryInverseDisabled {
        background-color: $white;
        color: $gray-5;
        cursor: default;

        svg {
            stroke: $gray-5;
        }
    }

    &.-primaryDisabled,
    &.-blueDisabled {
        background-color: $gray;
        color: $gray-5;
        cursor: default;
    }

    &.-basicDisabled {
        color: $gray-5;
        cursor: default;

        svg {
            stroke: $gray-5;
        }
    }

    &.-secondaryDisabled {
        color: $gray-5;
        cursor: default;
    }

    &.-tertiaryDisabled {
        color: $gray-5;
        cursor: default;
    }

    div {
        display: flex;
        align-items: center;

        svg {
            margin-right: 10px;
        }
    }
}
