.m-tab {
    border-bottom: 1px solid $gray-10;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @include media-up(m) {
        flex-direction: row;
    }

    &__tab {
        position: relative;
        padding: 20px 10px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: rgba($primary-color, 0.5);
        transition: color 0.3s;
        cursor: pointer;
        text-align: center;
        z-index: 1;

        @include media-up(m) {
            padding: 24px 34px;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            height: 3px;
            width: 100%;
            background-color: $primary-color;
            transition: transform 0.3s;
            transform: scaleX(0);
            pointer-events: none;
            z-index: -1;
        }

        &.-active {
            color: $primary-color;

            &::after {
                transform: scaleX(1);
            }
        }
    }
}
