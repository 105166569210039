.m-fileDragAndDrop {
    width: 100%;
    margin-bottom: 15px;

    .a-file {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: 100%;
        align-items: center;
        padding: 15px;
        background-color: $white;
        border: 1px dashed $gray-5;
        font-size: 14px;
        transition: 0.3s;
        cursor: pointer;

        &:hover,
        &:focus {
            border: 1px dashed $primary-color;
            transition: 0.3s;
        }

        &.-error {
            border-color: $red;

            &:hover,
            &:focus {
                border-color: $red;
            }
        }

        p {
            font-size: 13px;

            &.-action {
                font-weight: 600;
            }
        }

        .textContainer {
            margin-left: 15px;
            width: 50%;

            p {
                color: $gray-6;
            }
        }

        .imageContainer {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;

            p {
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .image {
                max-width: 100%;
                object-fit: contain;
            }

            .a-file__multiple--remove {
                right: -0.5rem;
            }
        }

        .uploadedFile {
            width: 100%;
            display: flex;
            background-color: #f7f7f9;
            position: relative;
            padding: 5px 15px;
            margin-top: 5px;

            .a-file__multiple--remove {
                top: auto;
            }
        }

        &__multiple {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            width: 100%;
            justify-content: center;

            @include media-up(l) {
                justify-content: flex-start;
            }

            div {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding-right: 20px;
                margin-top: 20px;

                img {
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                }
            }

            &--content {
                position: relative;
            }

            &--remove {
                position: absolute;
                cursor: pointer;
                top: -0.5rem;
                right: 0.75rem;
            }
        }
    }

    video {
        max-width: 100%;
        justify-self: center;
    }

    label {
        font-size: 13px;
        line-height: 16px;

        .a-require-star {
            color: $red;
        }
    }

    img {
        height: 100px;
    }

    .formNote {
        margin-top: 1rem;
        font-size: 14px;
        font-style: italic;
        color: $blue;

        &::before {
            font-style: normal;
            position: relative;
            content: '*';
            color: $red;
            left: -1px;
        }
    }
}
