.p-fplPreview {
    display: flex;

    &.-hasMessages {
        .m-main__contentContainer,
        .m-main__contentPrint {
            margin-right: 250px;

            @include media-down(xl) {
                margin-right: 0px;
            }
        }

        .m-main__contentContainer {
            width: 100%;
        }
    }

    &__messageListContainer {
        min-width: 0px;
        max-width: 0px;
        margin-left: 20px;
        height: 92vh;
        position: fixed;
        right: 0;
        transition: 0.5s;
        background-color: $white;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 2px 1px -1px rgba(0, 0, 0, 0.12);

        @include media-up(xl) {
            min-width: 250px;
            max-width: 250px;
        }

        &.-open {
            min-width: 250px;
            max-width: 250px;
            transition: 0.5s;
        }
    }

    &__messageList {
        height: 92vh;
        overflow-y: auto;

        @include scrollbar;

        @media print {
            height: 100%;

            div {
                break-inside: avoid;
            }
        }
    }

    &__message {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        padding: 10px;
        margin-bottom: 20px;
        border-radius: 6px;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #b9bfc9;
            margin-bottom: 5px;
            padding-bottom: 5px;
        }

        .title {
            display: flex;
            flex-direction: column;
        }

        .description {
            white-space: break-spaces;
        }
    }

    &__toggler {
        background-color: $primary-color;
        color: $white;
        position: absolute;
        top: 0;
        left: -45px;
        height: 45px;
        width: 45px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        display: none;

        @include media-down(xl) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
