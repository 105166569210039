.p-pibPreview {
    min-width: fit-content;

    h1 {
        padding: 30px 0 20px;
        font-weight: 400;

        @include media-down(s) {
            font-size: 32px;
        }

        @include media-down(xs) {
            font-size: 24px;
        }
    }

    h2,
    h3,
    h4 {
        font-weight: 400;
        color: $secondary-color;
        margin: 20px 0;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__headerIcons {
        display: flex;
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 30px;
            cursor: pointer;
        }
    }

    &__infoParagraph {
        display: flex;
        flex-direction: row;
        margin: 5px 0;
        color: $black;

        .label {
            min-width: 200px;
            display: inline-block;
            font-weight: 600;
            margin-right: 20px;
        }
    }

    &__notam {
        display: flex;
        justify-content: space-between;

        .info {
            display: flex;

            pre {
                margin: 0;
                font-size: 13px;
                line-height: 15px;
                margin-bottom: 15px;

                @include media-down(s) {
                    white-space: pre-wrap;
                }
            }
        }

        .marker {
            width: 50px;
        }

        .toDate {
            margin-left: 20px;
        }
    }

    &__meteo {
        margin-left: 50px;

        pre {
            margin: 0;
            font-size: 13px;
            line-height: 15px;
            margin-bottom: 15px;

            @include media-down(s) {
                white-space: normal;
            }
        }
    }

    &__map {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    &__mapContainer {
        display: flex;
        width: 100%;

        .m-map__container {
            max-width: 100%;
        }
    }

    &__mapLabel {
        margin-top: 10px;
        text-align: center;
    }

    .divider {
        display: inline-block;
        height: 1px;
        width: 100%;
        background-color: $gray-4;
        margin: 5px 0;
    }

    .m-tab {
        margin-bottom: 10px;
    }
}
