.m-fieldArray {
    &__remove {
        display: flex;
        align-items: center;
        .a-btn {
            margin-right: 200px;
            margin-left: -20px;
        }
    }
}
