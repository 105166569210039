.a-alert {
    position: fixed;
    padding: 12px 20px;
    background-color: #383f52;
    color: $white;
    font-size: 18px;
    line-height: 28px;
    border-radius: 10px;
    z-index: 99;
    cursor: pointer;
    opacity: 1;
    display: flex;
    align-items: center;

    &.-default {
        background-color: #383f52;
    }

    &.-success {
        background-color: $color-success;
    }

    &.-error {
        background-color: $color-error;
    }

    &.-center {
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
    }

    &.-right {
        top: 10px;
        right: 20px;
    }

    svg {
        margin-right: 10px;
    }
}
