.m-form {
    &__title {
        margin-bottom: 20px;
    }

    &__links {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__link {
        display: block;
        text-decoration: underline;
        transition: color 0.3s;

        &.-primary {
            color: $blue;
            font-weight: 600;

            &:hover {
                text-decoration: underline;
                color: $color-info;
            }
        }

        &.-secondary {
            color: $gray-5;
            font-style: italic;

            &:hover {
                text-decoration: underline;
                color: $gray-6;
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border-top: 1px solid $primary-color;
        margin-top: 20px;

        .right {
            display: flex;
            flex-wrap: wrap;

            .a-btn {
                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }
    }

    &.-login,
    &.-register,
    &.-checkEmail {
        margin-top: 10px;

        .a-btn {
            width: 100%;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 10px;

            div {
                justify-content: center;
            }
        }
    }

    &.-register {
        width: 100%;
        margin-top: 40px;
        .m-form__links {
            justify-content: flex-end;
        }
    }

    &.-checkEmail {
        span {
            font-size: 14px;
        }
    }

    &.-noticeBoard,
    &.-defaultSiteServices,
    &.-defaultSettings,
    &.-fpl,
    &.-pib,
    &.-user {
        display: flex;
        flex-direction: column;

        h5,
        h6 {
            margin: 25px 0;
        }

        h4 {
            margin-bottom: 25px;
        }

        .m-form__buttons {
            .a-btn {
                width: fit-content;
                margin-top: 20px;
            }
        }

        .backTo {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            font-size: 14px;
            color: $primary-color;

            svg {
                margin-right: 5px;
            }

            &:hover {
                cursor: pointer;
                color: $secondary-color;

                svg {
                    stroke: $secondary-color;
                }
            }
        }
    }

    &.-user {
        .info {
            .a-toggleSwitch {
                @include media-up(xl) {
                    margin-top: 35px;
                }
            }

            &:first-child {
                margin-top: 25px;
            }
        }

        .accept-terms {
            width: fit-content;
        }

        .m-table {
            margin-top: 20px;
        }
    }

    &.-defaultSettings {
        .m-multiselectGroup,
        .m-selectGroup,
        .m-inputGroup {
            max-width: 400px;
        }
    }

    &.-fpl,
    &.-pib {
        .equipment {
            display: flex;
            flex-direction: row;

            @include media-down(m) {
                flex-direction: column;
            }

            &__separator {
                display: flex;
                justify-content: center;
                margin-top: 30px;
                font-size: 24px;
                padding: 0 12px;

                @include media-down(m) {
                    display: none;
                }
            }

            .m-optionsGroup {
                width: 100%;
                margin-bottom: 15px;
            }
        }

        .conditionalField {
            &__select {
                padding-right: 5px;
            }

            &__value {
                padding-left: 0;

                .m-inputGroup {
                    margin-top: 25px;
                }
            }
        }

        .supplementaryField {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            border: 1px solid rgba($primary-color, 0.1);
            border-radius: 6px;
            padding: 10px 0;
            margin: 20px 0;
            box-shadow: 0 0px 0px 0px rgba($black, 0.2),
                0 0px 2px 0 rgba($black, 0.2);

            &__label {
                position: absolute;
                top: -14px;
                left: 14px;
                background-color: white;
                font-size: 14px;
                padding: 0 10px;
                color: $primary-color;
                font-weight: 600;

                &.-required {
                    &::after {
                        position: relative;
                        content: '*';
                        color: $red;
                        right: -5px;
                    }
                }
            }

            .coverCheckbox {
                padding-top: 38px;
                padding-bottom: 0;

                @include media-down(xl) {
                    padding-top: 25px;
                    padding-bottom: 32px;
                }

                @include media-down(l) {
                    padding-top: 35px;
                    padding-bottom: 0;
                }

                @include media-down(s) {
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
            }
        }
        .m-form__buttons {
            margin-top: 30px;
        }
    }

    &.-fpl {
        .m-checkboxGroup.-frame .a-checkbox {
            margin-right: 18px;
        }

        @include media-down(xl) {
            .m-checkboxGroup.-frame {
                height: 204px;
            }
        }

        @include media-down(m) {
            .m-checkboxGroup.-frame {
                height: auto;
            }
        }
    }

    &.-pib {
        .options {
            margin-top: 25px;
        }

        .m-checkboxGroup {
            height: 70%;
        }

        .m-checkboxGroup__options {
            margin-top: 15px;
        }

        @include media-down(l) {
            .m-checkboxGroup {
                height: 80%;
            }
        }

        @include media-down(m) {
            .m-checkboxGroup {
                height: auto;
            }

            .m-checkboxGroup__options {
                margin-top: 30px;
            }
        }
    }

    &.-fplTemplate {
        h5 {
            margin-bottom: 25px;
        }
    }

    &__registerStep {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding-bottom: 20px;

        &:not(:last-child)::after {
            content: '';
            position: absolute;
            top: 30px;
            left: 12px;
            height: calc(100% - 36px);
            width: 1px;
            background-color: $gray-5;
        }

        &--number {
            background-color: $gray-5;
            color: white;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 13px;
            position: absolute;
            top: 0;
            left: 0;

            &.-active {
                background-color: $primary-color;
            }
        }
    }

    &__registerStepHeader {
        text-align: center;
        font-size: 14px;
    }

    &__registerStepBody {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 20px 0px 40px;
    }

    &__registerStepButtons {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        .a-btn {
            width: 110px;
        }
    }
}
