.m-textarea {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    &.-error {
        textarea {
            border-color: $red;
        }
    }

    textarea {
        width: 100%;
        height: 100px;
        padding: 12px 15px;
        border-radius: 6px;
        border: 1px solid $gray-5;
        background-color: $white;
        outline: 0;
        color: $black;
        font-size: 13px;
        transition: border-color 0.3s, background-color 0.3s;
        filter: none;
        resize: none;

        &:focus {
            border-color: $secondary-color;
        }
    }

    &.-uppercase {
        textarea {
            text-transform: uppercase;
        }
    }

    &.-disabled {
        label {
            color: rgba($black, 0.7);
        }

        textarea {
            color: $black;
            opacity: 0.7;
        }
    }

    label {
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 4px;

        .a-require-star {
            color: $red;
        }
    }

    &__error {
        display: block;
        color: $red;
        opacity: 0;
        font-size: 13px;
        transition: opacity 0.3s;
        letter-spacing: -0.2px;
        line-height: 16px;
        margin-top: 3px;

        &.-active {
            opacity: 1;
        }
    }
}
